<template>
  <div class="home">
    <lunbo></lunbo>
    <div class="home-main">
      <div class="home-main__quality">
        <moduleTitle
          titleChinese="AI智能病历质控"
          titleEnglish="AI Intelligent Medical Record Quality Control"
        ></moduleTitle>
        <coreValues></coreValues>
        <router-link to="/quality" class="more" style="margin-top: 40px">了解更多</router-link>
      </div>
      <div class="home-main__diagnosis">
        <moduleTitle
          titleChinese="AI智能辅助诊疗"
          titleEnglish="AI intelligent assisted diagnosis and treatment"
        ></moduleTitle>
        <div class="home-main__diagnosis-img">
          <img src="@/assets/images/diagnosis.png" alt="" width="100%" />
        </div>
        <router-link to="/diagnosis" class="more" style="margin-top: 40px">了解更多</router-link>
      </div>
    </div>
    <cooperate></cooperate>
    <foot></foot>
  </div>
</template>

<script>
export default {
  components: {
    foot: () => import("@/components/footer.vue"),
    lunbo: () => import("@/components/lunbo.vue"),
    cooperate: () => import("@/components/cooperate.vue"),
    moduleTitle: () => import("@/components/moduleTitle.vue"),
    coreValues: () => import("@/components/coreValues.vue"),
  }
};
</script>

<style lang="scss" scoped>
$content-width: 1200px;
$content-bottom: 90px;
.home {
  // overflow-y: scroll;
  // margin-top: -64px;
  .more {
    display: block;
    margin: 0 auto;
    width: 158px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #fbb726;
    color: #fbb726;
    &:hover {
      background-color: #fbb726;
      color: #ffffff;
    }
  }
  &-main {
    background: url("../../assets/images/AI-bg-1.png") no-repeat;
    background-size: 868px 868px;
    background-position-y: 276px;
    background-position-x: -50px;
    margin-top: $content-bottom;
    &__quality {
      width: $content-width;
      margin: 0 auto 90px auto;
    }
    &__diagnosis {
      width: $content-width;
      margin: 0 auto 90px auto;
      &-img {
        width: 100%;
        margin-bottom: 64px;
      }
    }
  }
}
</style>